import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import { DEFAULT_NS, INITIAL_LANG } from "../constants/localization.constants";
import daDK from "../locales/da-DK/translation.json";
import enGB from "../locales/en-GB/translation.json";

i18next.use(initReactI18next).init({
  fallbackLng: INITIAL_LANG,
  lng: INITIAL_LANG,
  interpolation: {
    escapeValue: false,
  },
  defaultNS: DEFAULT_NS,
  resources: {
    "da-DK": daDK,
    "en-GB": enGB,
  },
});

export default i18next;
