import React, { memo } from "react";

import cx from "classnames";

import styles from "./Typography.module.scss";

export type HTagElement = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
>;

export interface HeadingProps extends HTagElement {
  level?: 1 | 2 | 3;
}

export const Headline = memo(
  ({ level = 1, className, children, ...rest }: HeadingProps) => {
    // tell TS that the element is a valid html element
    const H = `h${level}` as React.ElementType<HeadingProps>;
    return (
      <H
        className={cx(styles.heading, styles[`h${level}`], className)}
        {...rest}
      >
        {children}
      </H>
    );
  }
);

export interface TitleProps extends HTagElement {
  isBold?: boolean;
}

export const Title = memo(
  ({ isBold, className, children, ...rest }: TitleProps) => {
    return (
      <h3
        className={cx(styles.title, { [styles.bold]: isBold }, className)}
        {...rest}
      >
        {children}
      </h3>
    );
  }
);

export interface ParagraphProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  > {
  level?: 1 | 2;
  isBold?: boolean;
}

export const Body = memo(
  ({ level = 1, className, isBold, children, ...rest }: ParagraphProps) => {
    return (
      <p
        className={cx(
          styles.body,
          styles[`body${level}`],
          { [styles.bold]: isBold },
          className
        )}
        {...rest}
      >
        {children}
      </p>
    );
  }
);
