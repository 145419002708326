import { Fragment, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Button, {
  ButtonSizeEnum,
  ButtonTypeEnum,
} from "src/components/atoms/Button/Button";
import Divider from "src/components/atoms/Divider/Divider";
import LoadingSpinner from "src/components/atoms/LoadingSpinner/LoadingSpinner";
import { Headline } from "src/components/atoms/Typography/Typography";
import ContentWrapper from "src/components/layouts/ContentWrapper/ContentWrapper";
import { WarningsPathsEnum } from "src/features/warnings";

import { getAllAddresses, deleteAddress } from "../../api/address.api";
import AddressCard from "../../components/molecules/address-card/AddressCard";
import DeleteModal from "../../components/molecules/delete-modal/DeleteModal";
import { AddressPathsEnum } from "../../constants/address.paths";
import { UserAddresses, AddressDetails } from "../../types/address.types";
import styles from "./AddressListScreen.module.scss";

const AddressListScreen = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [addresses, setAddresses] = useState<UserAddresses>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentAddress, setCurrentAddress] = useState<string>("");

  const getAddresses = () => {
    getAllAddresses()
      .then(response => {
        /**
         * update needed after completion of API
         */
        setAddresses(response.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleDeleteAddress = (id: string) => {
    setCurrentAddress(id);
    setIsModalOpen(true);
  };

  const closeModal = async (action: boolean) => {
    setIsModalOpen(false);
    setCurrentAddress("");
    if (action) {
      setLoading(true);
      try {
        await deleteAddress(currentAddress);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }

      getAddresses();
    }
  };

  useEffect(() => {
    getAddresses();
  }, []);

  const renderPrimaryAddress = () => {
    const { street, number, floor, door, zipCode, cityName } =
      addresses?.primary as AddressDetails;

    const firstLine = [street, number, floor, door].join(" ");
    const secondLine = [zipCode, cityName].join(" ");
    return (
      <>
        <div className={styles.addressCard}>
          <AddressCard firstLine={firstLine} secondLine={secondLine} />
        </div>
        <Divider />
      </>
    );
  };
  return (
    <ContentWrapper className={styles.container}>
      {isModalOpen && <DeleteModal closeModal={action => closeModal(action)} />}
      <div className={styles.headingSection}>
        <Headline level={2} className={styles.heading}>
          {t("addressList.title")}
        </Headline>
        <Link to={AddressPathsEnum.ADDRESS_ADD} className={styles.addAddress}>
          <Button
            label={t("addressAdd.title")}
            buttonType={ButtonTypeEnum.PRIMARY}
            buttonSize={ButtonSizeEnum.SMALL}
          />
        </Link>
      </div>
      {loading ? (
        <LoadingSpinner className={styles.loading} />
      ) : (
        <>
          <div className={styles.body}>
            {addresses?.primary && renderPrimaryAddress()}
            {addresses?.secondary.map((item: AddressDetails, index: number) => {
              const { street, number, floor, door, zipCode, cityName, id } =
                item;

              const firstLine = [street, number, floor, door].join(" ");
              const secondLine = [zipCode, cityName].join(" ");
              return (
                <Fragment key={id}>
                  {index !== 0 && <Divider />}
                  <div className={styles.addressCard}>
                    <AddressCard
                      firstLine={firstLine}
                      secondLine={secondLine}
                      onClick={() => handleDeleteAddress(id)}
                    />
                  </div>
                </Fragment>
              );
            })}
          </div>
          <div className={styles.action}>
            <Link to={WarningsPathsEnum.ALL_WARNINGS}>
              <Button
                label={t("default.back")}
                buttonType={ButtonTypeEnum.SECONDARY}
                buttonSize={ButtonSizeEnum.SMALL}
                fullWidth
              />
            </Link>
          </div>
        </>
      )}
    </ContentWrapper>
  );
};

export default AddressListScreen;
