import { useTranslation } from "react-i18next";

import Button, {
  ButtonSizeEnum,
  ButtonTypeEnum,
} from "src/components/atoms/Button/Button";
import { Body, Headline } from "src/components/atoms/Typography/Typography";

import styles from "./DeleteModal.module.scss";

type DeleteModalProps = {
  closeModal: (action: boolean) => void;
};

const DeleteModal = ({ closeModal }: DeleteModalProps) => {
  const { t } = useTranslation();

  const handleClick = (action: boolean) => {
    closeModal(action);
  };

  return (
    <div className={styles.modal}>
      <div className={styles.body}>
        <Headline className={styles.title} level={3}>
          Slet Adresse
        </Headline>
        <Body className={styles.description}>
          Din adresse vil blive slettet og kan ikke fortrydes senere.
        </Body>
        <Button
          className={styles.deleteButton}
          label={t("default.delete")}
          buttonType={ButtonTypeEnum.PRIMARY}
          buttonSize={ButtonSizeEnum.SMALL}
          fullWidth
          onClick={() => handleClick(true)}
        />
        <Button
          label={t("default.cancel")}
          buttonType={ButtonTypeEnum.SECONDARY}
          buttonSize={ButtonSizeEnum.SMALL}
          fullWidth
          onClick={() => handleClick(false)}
        />
      </div>
    </div>
  );
};

export default DeleteModal;
