import { memo, Suspense, useEffect, useState } from "react";

import qs from "query-string";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";

import { OnboardingPathsEnum } from "src/features/onboarding/constants/onboarding.paths";
import { setAuthUser } from "src/features/warnings";
import { saveToken, getToken } from "src/helpers/auth.helper";
import { useAppDispatch } from "src/redux/store";
import { RouteItemDef } from "src/types/route.types";

import { PUBLIC_LIST, PRIVATE_LIST } from "./routes.config";

const Routes = memo(() => {
  const location = useLocation();
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const { token } = qs.parse(location.search);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (token) {
      saveToken(token as string);
      dispatch(setAuthUser(true));
    } else if (getToken()) {
      dispatch(setAuthUser(true));
    } else {
      setShouldRedirect(true);
    }
  }, [token, dispatch]);

  const routeWrapper = ({ id, path, component }: RouteItemDef) => {
    return (
      <Route
        exact
        key={id}
        path={path}
        render={routeProps => {
          const Component = component as any;
          const renderContent = <Component {...routeProps} />;

          return renderContent;
        }}
      />
    );
  };

  if (shouldRedirect && location.pathname !== OnboardingPathsEnum.HOME)
    return <Redirect to="/" />;

  return (
    <Suspense fallback={<>Loading...</>}>
      <Switch>
        {PUBLIC_LIST.map(route => routeWrapper(route))}
        {PRIVATE_LIST.map(route => routeWrapper(route))}
        <Redirect to="/" />
      </Switch>
    </Suspense>
  );
});

export default Routes;
