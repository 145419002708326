import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { updateOnboardingStatus } from "../api/onboarding.api";

export const ONBOARDING_FEATURE_KEY = "onboarding";

const initialState = {
  loading: false,
  firstVisit: false,
  error: false,
};

export const updateFirstTimeVisit = createAsyncThunk(
  `${ONBOARDING_FEATURE_KEY}/updateFirstTime`,
  async (isVisited: boolean, { rejectWithValue }) => {
    try {
      const response = await updateOnboardingStatus(isVisited);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

const onboardingSlice = createSlice({
  name: ONBOARDING_FEATURE_KEY,
  initialState,
  reducers: {
    setFirstTimeVisit: (state, action) => {
      state.firstVisit = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(updateFirstTimeVisit.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(updateFirstTimeVisit.fulfilled, (state, action) => {
      state.loading = false;
      state.firstVisit = action.payload;
    });
    builder.addCase(updateFirstTimeVisit.rejected, state => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const { setFirstTimeVisit } = onboardingSlice.actions;

export const onboardingReducer = onboardingSlice.reducer;
