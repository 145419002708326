/* eslint-disable import/no-duplicates */
import { format, isToday, differenceInCalendarDays, Locale } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { enGB, da } from "date-fns/locale";

import { DateFormats, TIMEZONE } from "src/constants/date.constants";
import { i18next } from "src/features/localization/localization";

type locale = {
  [key: string]: Locale;
};

const locales: locale = { "en-GB": enGB, "da-DK": da };

const SIX_MONTHS = 365 / 2;

export const localizedFormatWrapper = (
  date: Date,
  formatStr = "PP",
  configs = {}
) => {
  return format(date, formatStr, {
    locale: locales[i18next.language],
    ...configs,
  });
};

/**
 * @param timeStamp timestamp in seconds using UTC time zone
 * @returns formatted string date
 */
export const formatTimeStamp = (timeStamp: number) => {
  const timeStampInMilliseconds = timeStamp * 1000;
  const now = utcToZonedTime(Date.now(), TIMEZONE);
  const dateTimeStamp = utcToZonedTime(timeStampInMilliseconds, TIMEZONE);

  /** if today format like this "Today 22:34" */
  if (isToday(dateTimeStamp))
    return `${i18next.t("default.today")} ${localizedFormatWrapper(
      dateTimeStamp,
      DateFormats.HOURS_MINS
    )}`;
  /** during the week format like this "Monday 22:34" */
  if (differenceInCalendarDays(now, dateTimeStamp) < 7)
    return localizedFormatWrapper(
      dateTimeStamp,
      DateFormats.FULL_DAY_NAME__HOURS_MINS
    );

  /**
   *  if less than 6 months passed that date
   *  format like this "24. Feb. 22:34"
   */
  if (differenceInCalendarDays(now, dateTimeStamp) < SIX_MONTHS)
    return localizedFormatWrapper(
      dateTimeStamp,
      DateFormats.DAY_NUMBER__SHORT_MONTH__HOURS_MINS
    );

  /** for previous years format like this "25. Jan. 2011" */
  return localizedFormatWrapper(
    dateTimeStamp,
    DateFormats.DAY_NUMBER__SHORT_MONTH__YEAR_NUMBER
  );
};
