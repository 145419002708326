import { RouteItemDef } from "src/types/route.types";

import { OnboardingPathsEnum } from "../constants/onboarding.paths";
import OnboardingScreen from "../screens/OnboardingScreen";

const ONBOARDING_SCREEN: RouteItemDef = {
  id: "onboarding",
  path: OnboardingPathsEnum.HOME,
  component: OnboardingScreen,
};

export const ONBOARDING_ROUTES = [ONBOARDING_SCREEN];
