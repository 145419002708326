import { useEffect, Fragment } from "react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Button, {
  ButtonSizeEnum,
  ButtonTypeEnum,
} from "src/components/atoms/Button/Button";
import Divider from "src/components/atoms/Divider/Divider";
import LoadingSpinner from "src/components/atoms/LoadingSpinner/LoadingSpinner";
import { Headline, Body } from "src/components/atoms/Typography/Typography";
import ContentWrapper from "src/components/layouts/ContentWrapper/ContentWrapper";
import { AddressPathsEnum } from "src/features/address";
import { getToken } from "src/helpers/auth.helper";
import { useAppDispatch, useAppSelector } from "src/redux/store";

import WarningCard from "../../components/WarningCard/WarningCard";
import { getWarnings } from "../../redux/warnings.slice";
import { Warning } from "../../types/warnings.types";
import styles from "./WarningsListScreen.module.scss";

const WarningsListScreen = () => {
  const { t } = useTranslation();
  const warnings = useAppSelector(state => state.warnings.warnings);
  const loading = useAppSelector(state => state.warnings.loading);
  const dispatch = useAppDispatch();

  useEffect(() => {
    /**
     * another check for the availability of access token before
     * calling the api, the first one is in `Routes.js`
     */
    if (getToken()) dispatch(getWarnings());
  }, [dispatch]);

  return (
    <ContentWrapper className={styles.wrapper}>
      <header className={styles.headerContainer}>
        <Headline level={2} className={styles.header}>
          {t("default.notifications")}
        </Headline>
        <Link to={AddressPathsEnum.ADDRESS_LIST} className={styles.addAddress}>
          <Button
            label={t("addressList.title")}
            buttonType={ButtonTypeEnum.PRIMARY}
            buttonSize={ButtonSizeEnum.SMALL}
          />
        </Link>
      </header>
      {!!warnings.length && (
        <div className={styles.cardsWrapper}>
          {warnings.map((warning: Warning, index: number) => (
            <Fragment key={warning.warningId}>
              {index !== 0 && <Divider className={styles.driftDivider} />}
              <WarningCard warning={warning} />
            </Fragment>
          ))}
        </div>
      )}
      {!warnings.length && (
        <div className={styles.noNotificationsWrapper}>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <Headline level={3} className={styles.subHeader}>
                {t("default.noWarnings")}
              </Headline>
              <Body className={styles.paragraph}>
                {t("default.noWarningsNote")}
              </Body>
            </>
          )}
        </div>
      )}
    </ContentWrapper>
  );
};

export default WarningsListScreen;
