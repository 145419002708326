import { memo } from "react";

import cx from "classnames";

import styles from "./LoadingSpinner.module.scss";

export interface LoadingSpinnerProps {
  className?: string;
}

const LoadingSpinner = memo(({ className }: LoadingSpinnerProps) => {
  return (
    <div className={cx(styles.loading, className)}>
      <div className={styles.spinner}>
        <div className={styles.circle}>
          <div className={styles.circleInner} />
        </div>
        <div className={cx(styles.circle, styles.circle2)}>
          <div className={styles.circleInner} />
        </div>
      </div>
    </div>
  );
});

export default LoadingSpinner;
