import { useState } from "react";

import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { ActionMeta, SingleValue } from "react-select";

import Button, {
  ButtonSizeEnum,
  ButtonTypeEnum,
} from "src/components/atoms/Button/Button";
import LoadingSpinner from "src/components/atoms/LoadingSpinner/LoadingSpinner";
import { Title } from "src/components/atoms/Typography/Typography";
import ContentWrapper from "src/components/layouts/ContentWrapper/ContentWrapper";

import {
  addAddress,
  getAddressFromDawa,
  getKvhxFromDawa,
} from "../../api/address.api";
import AddressSelect from "../../components/molecules/address-select/AddressSelect";
import { AddressPathsEnum } from "../../constants/address.paths";
import { AddAddressType, DawaAdressType } from "../../types/address.types";
import styles from "./AddressAddScreen.module.scss";

type AddressOption = {
  label: string;
  value: string;
  street: string;
  zipCode: string;
};
type OnChangeType = (
  newValue: SingleValue<AddressOption>,
  actionMeta: ActionMeta<AddressOption>
) => void;

const initialAddress: AddressOption = {
  label: "",
  value: "",
  street: "",
  zipCode: "",
};

const AddressAddScreen = () => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const [address, setAddress] = useState<AddressOption>(initialAddress);
  const [error, setError] = useState<string>("");
  const saveButtonType =
    !error.length && address.label.length
      ? {
          buttonType: ButtonTypeEnum.PRIMARY,
          isDisabled: false,
        }
      : {
          buttonType: ButtonTypeEnum.SECONDARY,
          isDisabled: true,
        };

  const loadOptions: any = async (
    input: string,
    callback: (options: AddressOption[]) => void
  ) => {
    let addresses: [] = [];
    try {
      const response = await getAddressFromDawa(input);
      addresses = await response.data;
    } catch {
      addresses = [];
    }
    callback(
      addresses.map((item: DawaAdressType) => {
        const { tekst, adresse } = item;
        const { id, vejnavn, postnr } = adresse;
        return {
          label: tekst,
          value: id,
          street: vejnavn,
          zipCode: postnr,
        };
      })
    );
  };

  const handleChange: OnChangeType = selected => {
    if (selected?.value) {
      setError("");
      setAddress({
        label: selected.label,
        value: selected.value,
        street: selected.street,
        zipCode: selected.zipCode,
      });
    } else if (selected?.label) {
      setAddress({ ...initialAddress, label: selected.label });
      setError(t("addressAdd.invalidAddress"));
    }
  };

  const saveAddress = async () => {
    let response;
    setLoading(true);
    try {
      response = await getKvhxFromDawa(address.value);
      response = await response.data;
      const data: AddAddressType = {
        kvhx: response.kvhx,
        street: address.street,
        zipCode: Number(address.zipCode),
      };
      if (data.kvhx.length) {
        await addAddress(data);
        setAddress(initialAddress);
        history.push(AddressPathsEnum.ADDRESS_LIST);
      }
    } catch (err: any) {
      setLoading(false);
      setError(err.message as string);
      setAddress(initialAddress);
    }
  };

  return (
    <ContentWrapper className={styles.wrapper}>
      {loading ? (
        <LoadingSpinner className={styles.loading} />
      ) : (
        <>
          <Title className={styles.title} isBold>
            {t("addressAdd.title")}
          </Title>
          <AddressSelect
            label={t("default.address")}
            loadOptions={loadOptions}
            placeholder={t("addressAdd.placeholder")}
            {...(address.label.length && { value: address })}
            onChange={handleChange}
            error={error}
          />
          <div className={styles.action}>
            <Link to={AddressPathsEnum.ADDRESS_LIST} className={styles.cancel}>
              <Button
                label={t("default.cancel")}
                buttonType={ButtonTypeEnum.SECONDARY}
                buttonSize={ButtonSizeEnum.SMALL}
                fullWidth
              />
            </Link>
            <Button
              className={styles.submit}
              label={t("default.save")}
              {...saveButtonType}
              buttonSize={ButtonSizeEnum.SMALL}
              fullWidth
              onClick={saveAddress}
            />
          </div>
        </>
      )}
    </ContentWrapper>
  );
};

export default AddressAddScreen;
