import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getAllWarnings, readWarningAPI } from "../api/warnings.api";
import { ReadWarningReqDef, Warning } from "../types/warnings.types";

export const WARNINGS_FEATURE_KEY = "warnings";

interface SliceState {
  warnings: Array<Warning>;
  loading: boolean;
  error: string | boolean | undefined | null;
  warning: any;
  isUserAuthorized: boolean;
}

const initialState: SliceState = {
  warnings: [],
  loading: false,
  error: null,
  warning: null,
  isUserAuthorized: false,
};

export const getWarnings = createAsyncThunk(
  `${WARNINGS_FEATURE_KEY}/getWarnings`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await getAllWarnings();
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const readWarning = createAsyncThunk(
  `${WARNINGS_FEATURE_KEY}/readWarning`,
  async (params: ReadWarningReqDef, { rejectWithValue }) => {
    try {
      const response = await readWarningAPI(params);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

const warningsSlice = createSlice({
  name: WARNINGS_FEATURE_KEY,
  initialState,
  reducers: {
    setAuthUser: (state, action) => {
      state.isUserAuthorized = action.payload;
    },
    setWarning: (state, action) => {
      state.warning = action.payload;
    },
    clearWarning: state => {
      state.warning = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(getWarnings.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(getWarnings.fulfilled, (state, action) => {
      state.loading = false;
      state.warnings = action.payload;
    });
    builder.addCase(getWarnings.rejected, state => {
      state.loading = false;
      //   state.error = action.payload;
    });
    builder.addCase(readWarning.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(readWarning.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(readWarning.rejected, state => {
      state.loading = false;
    });
  },
});

export const { setAuthUser, setWarning, clearWarning } = warningsSlice.actions;

export const warningsReducer = warningsSlice.reducer;
