import { BrowserRouter as Router } from "react-router-dom";

import Routes from "src/routes/Routes";
import "src/styles/index.scss";

function App() {
  return (
    <Router>
      <Routes />
    </Router>
  );
}

export default App;
