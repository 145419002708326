import { memo, useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import homeImage from "src/assets/images/home.svg";
import Button, { ButtonTypeEnum } from "src/components/atoms/Button/Button";
import LoadingSpinner from "src/components/atoms/LoadingSpinner/LoadingSpinner";
import { Headline, Body } from "src/components/atoms/Typography/Typography";
import ContentWrapper from "src/components/layouts/ContentWrapper/ContentWrapper";
import { AddressPathsEnum } from "src/features/address";
import { getAllAddresses } from "src/features/address/api/address.api";
import { AddressDetails } from "src/features/address/types/address.types";
import { WarningsPathsEnum } from "src/features/warnings";
import { useAppSelector, useAppDispatch } from "src/redux/store";

import { getOnboardingStatus } from "../api/onboarding.api";
import { updateFirstTimeVisit } from "../redux/onboarding.slice";
import styles from "./OnboardingScreen.module.scss";

const OnboardingScreen = memo(() => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [primaryAddress, setPrimaryAddress] = useState<AddressDetails>();
  const isUserAuthorized = useAppSelector(
    state => state.warnings.isUserAuthorized
  );
  const { t } = useTranslation();

  const detectOnboardingStatus = useCallback(async () => {
    let firstTimeVisit = false;
    try {
      const response = await getOnboardingStatus();
      firstTimeVisit = await response.data;
    } catch {
      firstTimeVisit = true;
    }
    setLoading(false);
    if (!firstTimeVisit) {
      history.push(WarningsPathsEnum.ALL_WARNINGS);
    }
    // get the primary address
    try {
      const response = await getAllAddresses();
      setPrimaryAddress(response.data.primary);
    } catch (error) {
      console.error(error);
    }
  }, [history]);

  useEffect(() => {
    detectOnboardingStatus();
  }, [history, detectOnboardingStatus]);

  const handleContinueButton = () => {
    dispatch(updateFirstTimeVisit(true));
    history.push(WarningsPathsEnum.ALL_WARNINGS);
  };

  const handleGoToAddAddress = () => {
    dispatch(updateFirstTimeVisit(true));
    history.push(AddressPathsEnum.ADDRESS_ADD);
  };

  const renderPrimaryAddress = () => {
    const { street, number, floor, door, zipCode, cityName } =
      primaryAddress as AddressDetails;
    const addressString = [street, number, floor, door, zipCode, cityName].join(
      " "
    );
    return addressString;
  };

  return (
    <ContentWrapper className={styles.wrapper}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className={styles.imgBox}>
            <img src={homeImage} alt="home icon" />
          </div>
          <Headline level={2} className={styles.heading}>
            {isUserAuthorized
              ? t("default.introHeadline")
              : t("default.noAddressFound")}
          </Headline>
          <Body className={styles.body}>
            {isUserAuthorized
              ? t("default.introDescription")
              : t("default.noAddressGuide")}
          </Body>
          {isUserAuthorized && (
            <>
              <div className={styles.address}>
                <Body className={styles.addressTitle} level={2}>
                  {t("default.address")}
                </Body>
                <Body className={styles.addressName}>
                  {primaryAddress
                    ? renderPrimaryAddress()
                    : t("addressList.unavailableAddress")}
                </Body>
              </div>
              <Button
                className={styles.addButton}
                label={t("default.addAdditionalAddress")}
                onClick={handleGoToAddAddress}
              />
              <Button
                label={t("default.proceed")}
                onClick={handleContinueButton}
                buttonType={ButtonTypeEnum.SECONDARY}
              />
            </>
          )}
        </>
      )}
    </ContentWrapper>
  );
});

export default OnboardingScreen;
