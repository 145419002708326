import cookie from "react-cookies";

import { AUTH_ACCESS_TOKEN } from "src/constants/auth.keys";
import { AuthStateDef } from "src/types/auth.types";

/**
 * Loads token from session cookie
 */
export const getToken = () => {
  return cookie.load(AUTH_ACCESS_TOKEN);
};

/**
 * Save token in session cookie
 */
export const saveToken = (token: string) => {
  cookie.save(AUTH_ACCESS_TOKEN, token, { path: "/" });
};

/**
 * Clear token from session cookie
 */
export const clearToken = () => {
  return cookie.remove(AUTH_ACCESS_TOKEN, { path: "/" });
};

/**
 * simplify code in slice with helper
 */
export const authErrorHelper = (state: AuthStateDef) => {
  state.isAuthenticated = false;
  state.error = true;
  state.loading = false;
};
