import { ReactElement, RefAttributes } from "react";

import cx from "classnames";
import { GroupBase } from "react-select";
import AsyncSelect, { AsyncProps } from "react-select/async";
import Select from "react-select/dist/declarations/src/Select";

import styles from "./AddressSelect.module.scss";

type AsyncSelectProps = <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: Omit<
    AsyncProps<Option, IsMulti, Group> &
      RefAttributes<Select<Option, IsMulti, Group>> & {
        label: string;
        error?: string;
      },
    "noOptionsMessage" | "components"
  >
) => ReactElement;

const AddressSelect: AsyncSelectProps = props => {
  const { className, label, error } = props;
  return (
    <>
      <span className={styles.label}>{label}</span>
      <AsyncSelect
        {...props}
        className={cx(styles.select, className)}
        components={{
          IndicatorsContainer: () => null,
        }}
        noOptionsMessage={() => null}
      />
      {error?.length ? <span className={styles.error}>{error}</span> : ""}
    </>
  );
};

export default AddressSelect;
