import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";

import Button, {
  ButtonSizeEnum,
  ButtonTypeEnum,
} from "src/components/atoms/Button/Button";
import Divider from "src/components/atoms/Divider/Divider";
import {
  Title,
  Headline,
  Body,
} from "src/components/atoms/Typography/Typography";
import ContentWrapper from "src/components/layouts/ContentWrapper/ContentWrapper";
import { WarningsPathsEnum } from "src/features/warnings";
import { useAppSelector, useAppDispatch } from "src/redux/store";

import { formatTimeStamp } from "../../helpers/date.helper";
import { readWarning } from "../../redux/warnings.slice";
import { Warning } from "../../types/warnings.types";
import styles from "./WarningDetailsScreen.module.scss";

const WarningDetailsScreen = () => {
  const { t } = useTranslation();
  const warning: Warning = useAppSelector(state => state.warnings.warning);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (warning?.isRead === false)
      dispatch(readWarning({ warningId: warning?.warningId }));
  }, [warning, dispatch]);

  // TODO: get warning details from an API using the warningId in URL
  if (!warning) return <Redirect to="/warnings" />;

  return (
    <ContentWrapper className={styles.container}>
      {warning.addressName && (
        <Title className={styles.addressName}>{warning.addressName}</Title>
      )}
      <Headline level={2} className={styles.headline}>
        {warning.headline}
      </Headline>
      <Title className={styles.senderName}>{warning.senderName}</Title>
      <Body
        className={styles.fullMessage}
        dangerouslySetInnerHTML={{ __html: warning.fullMessage ?? "" }}
      />
      <Divider />
      <Body className={styles.date}>
        {t("default.notificationReceived")}{" "}
        {formatTimeStamp(warning.receivedTime)}
      </Body>
      <Link to={WarningsPathsEnum.ALL_WARNINGS} className={styles.back}>
        <Button
          label={t("default.back")}
          buttonType={ButtonTypeEnum.SECONDARY}
          buttonSize={ButtonSizeEnum.SMALL}
          fullWidth
        />
      </Link>
    </ContentWrapper>
  );
};

export default WarningDetailsScreen;
