import { RouteItemDef } from "src/types/route.types";

import { AddressPathsEnum } from "../constants/address.paths";
import AddressAddScreen from "../screens/AddressAddScreen/AddressAddScreen";
import AddressListScreen from "../screens/AddressListScreen/AddressListScreen";

const ADDRESS_ADD_SCREEN: RouteItemDef = {
  id: "addressAdd",
  path: AddressPathsEnum.ADDRESS_ADD,
  component: AddressAddScreen,
};
const ADDRESS_LIST_SCREEN: RouteItemDef = {
  id: "addressList",
  path: AddressPathsEnum.ADDRESS_LIST,
  component: AddressListScreen,
};

export const ADDRESS_ROUTES = [ADDRESS_ADD_SCREEN, ADDRESS_LIST_SCREEN];
