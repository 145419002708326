import { combineReducers } from "@reduxjs/toolkit";

import { onboardingReducer } from "src/features/onboarding";
import { warningsReducer } from "src/features/warnings";

const rootReducer = combineReducers({
  warnings: warningsReducer,
  onboarding: onboardingReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
