import axios, { AxiosRequestConfig } from "axios";

import { ENV } from "src/constants/env";
import { getToken } from "src/helpers/auth.helper";

/**
 * Adds authorization headers to API calls
 * @param {AxiosRequestConfig} request
 */
const authInterceptor = async (request: AxiosRequestConfig) => {
  const accessToken = getToken();

  if (accessToken && request && request.headers) {
    request.headers.Authorization = `Bearer ${accessToken}`;
    return request;
  }

  if (!accessToken) {
    // TODO: handle when UNAUTHORIZED;
    // return Promise.reject(ApiStatusCodes.UNAUTHORIZED);
    return request;
  }

  return request;
};

/** Setup an API instance */
export const api = axios.create({
  baseURL: ENV.WARNINGS_API_BASE_URL,
  headers: { "Content-Type": "application/json", Accept: "text/plain" },
});

api.interceptors.request.use(authInterceptor);
