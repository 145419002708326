import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  forwardRef,
  memo,
  Ref,
} from "react";

import cx from "classnames";

import styles from "./Button.module.scss";

export enum ButtonTypeEnum {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  ON_FILL = "onFill",
}

export enum ButtonSizeEnum {
  SMALL = "small",
  MEDUIM = "meduim",
  LARGE = "large",
}

export interface ButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  label?: string;
  buttonType?: ButtonTypeEnum;
  buttonSize?: ButtonSizeEnum;
  isDisabled?: boolean;
  /**
   * Set to true, if the button will submit a form
   */
  submit?: boolean;
  /**
   * Whether the button should take up the full width of container (optional)
   */
  fullWidth?: boolean;
  ref?: Ref<HTMLButtonElement>;
}

const Button = memo(
  forwardRef<HTMLButtonElement, ButtonProps>(
    (
      {
        label,
        buttonType = ButtonTypeEnum.PRIMARY,
        buttonSize = ButtonSizeEnum.MEDUIM,
        submit,
        fullWidth,
        isDisabled,
        className,
        ...rest
      },
      ref
    ) => {
      const button = (
        <button
          type={submit ? "submit" : "button"}
          className={cx(
            styles.button,
            className,
            {
              [styles.fullWidth]: fullWidth,
              [styles.isDisabled]: isDisabled,
            },
            styles[buttonType],
            styles[buttonSize]
          )}
          ref={ref}
          {...rest}
        >
          {label}
        </button>
      );

      return button;
    }
  )
);

export default Button;
