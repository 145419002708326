import { memo } from "react";

import cx from "classnames";

import styles from "./Divider.module.scss";

interface DividerProps {
  className?: string;
}

const Divider = memo(({ className }: DividerProps) => {
  return <div className={cx(styles.divider, className)} />;
});

export default Divider;
