import { RouteItemDef } from "src/types/route.types";

import { WarningsPathsEnum } from "../constants/warnings.paths";
import WarningDetailsScreen from "../screens/WarningDetailsScreen/WarningDetailsScreen";
import WarningsListScreen from "../screens/WarningsListScreen/WarningsListScreen";

const WARNINGS_SCREEN: RouteItemDef = {
  id: "warnings",
  path: WarningsPathsEnum.ALL_WARNINGS,
  component: WarningsListScreen,
};

const WARNING_DETAILS_SCREEN: RouteItemDef = {
  id: "warningDetails",
  path: WarningsPathsEnum.WARNING_DETAILS,
  component: WarningDetailsScreen,
};

export const WARNINGS_ROUTES = [WARNINGS_SCREEN, WARNING_DETAILS_SCREEN];
