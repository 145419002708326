import { AxiosResponse } from "axios";

import { api } from "src/api/api";

import { WarningsEndpointsEnum } from "../constants/warnings.endpoints";
import { ReadWarningReqDef } from "../types/warnings.types";

export const getAllWarnings = (): Promise<AxiosResponse> => {
  return api.get(`${WarningsEndpointsEnum.GET_ALL_WARNINGS}`);
};

export const readWarningAPI = ({
  warningId,
}: ReadWarningReqDef): Promise<AxiosResponse> => {
  return api.get(`${WarningsEndpointsEnum.READ_WARNING}`, {
    params: { warningId },
  });
};
