import axios, { AxiosResponse } from "axios";

import { api } from "src/api/api";

import { AddressEndpointsEnum } from "../constants/address.endpoints";
import { AddAddressType } from "../types/address.types";

export const getAddressFromDawa = (address: string) => {
  return axios.get(
    `https://api.dataforsyningen.dk/adresser/autocomplete?q=${address}&caretpos=18&fuzzy=`
  );
};

export const getKvhxFromDawa = (id: string) => {
  return axios.get(`https://api.dataforsyningen.dk/adresser/${id}`);
};

export const getAllAddresses = (): Promise<AxiosResponse> => {
  return api.get(AddressEndpointsEnum.GET_ALL_ADDRESSES);
};

export const addAddress = (data: AddAddressType): Promise<AxiosResponse> => {
  return api.post(AddressEndpointsEnum.ADD_ADDRESS, data);
};

export const deleteAddress = (addressId: string): Promise<AxiosResponse> => {
  const url = `${AddressEndpointsEnum.DELETE_ADDRESS}?addressId=${addressId}`;
  return api.delete(url);
};
