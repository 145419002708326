import { Link, generatePath } from "react-router-dom";

import { Title, Body } from "src/components/atoms/Typography/Typography";
import { useAppDispatch } from "src/redux/store";

import { WarningsPathsEnum } from "../../constants/warnings.paths";
import { formatTimeStamp } from "../../helpers/date.helper";
import { setWarning } from "../../redux/warnings.slice";
import { Warning } from "../../types/warnings.types";
import styles from "./WarningCard.module.scss";

interface IProps {
  warning: Warning;
}

const WarningCard = ({ warning }: IProps) => {
  const dispatch = useAppDispatch();

  const url = generatePath(WarningsPathsEnum.WARNING_DETAILS, {
    warningId: warning.warningId,
  });

  return (
    <Link
      className={styles.card}
      to={url}
      onClick={() => dispatch(setWarning(warning))}
    >
      <div className={styles.addressAndTimeRow}>
        {warning.addressName && (
          <Body level={2} className={styles.addressName}>
            {warning.addressName}
          </Body>
        )}
        <Body level={2} isBold={!warning.isRead} className={styles.date}>
          {formatTimeStamp(warning.receivedTime)}
        </Body>
      </div>
      <Title isBold={!warning.isRead} className={styles.senderName}>
        {warning.senderName}
      </Title>
      <Body className={styles.shortMessage}>{warning.shortMessage}</Body>
      <span className={styles.arrow} />
    </Link>
  );
};

export default WarningCard;
