import { useTranslation } from "react-i18next";

import CloseButton from "src/assets/images/closeButton.svg";
import { Body } from "src/components/atoms/Typography/Typography";

import styles from "./AddressCard.module.scss";

type AddressCardProps = {
  firstLine: string;
  secondLine: string;
  onClick?: () => void;
};

const AddressCard = ({ firstLine, secondLine, onClick }: AddressCardProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.card}>
      <div>
        <Body className={styles.strreet}>{firstLine}</Body>
        <Body level={2} className={styles.details}>
          {secondLine}
        </Body>
      </div>
      {onClick && (
        <button onClick={onClick} className={styles.action}>
          <img src={CloseButton} alt={t("default.delete")} />
        </button>
      )}
    </div>
  );
};

export default AddressCard;
