import { memo, ReactNode } from "react";

import cx from "classnames";

import styles from "./ContentWrapper.module.scss";

interface ContentWrapperProps {
  center?: boolean;
  className?: string;
  children: ReactNode;
  fullHeight?: boolean;
}

const ContentWrapper = memo(
  ({ children, className, center, fullHeight }: ContentWrapperProps) => {
    return (
      <div
        className={cx(
          styles.content,
          className,
          { [styles.center]: center },
          { [styles.fullHeight]: fullHeight }
        )}
      >
        {children}
      </div>
    );
  }
);

export default ContentWrapper;
