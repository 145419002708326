import { AxiosResponse } from "axios";

import { api } from "src/api/api";

import { OnboardingEndpointsEnum } from "../constants/onboarding.endpoints";

export const getOnboardingStatus = (): Promise<AxiosResponse> => {
  return api.get(OnboardingEndpointsEnum.GET_FIRST_TIME_VISIT_STATUS);
};

export const updateOnboardingStatus = (
  isVisited: boolean
): Promise<AxiosResponse> => {
  return api.put(
    OnboardingEndpointsEnum.GET_FIRST_TIME_VISIT_STATUS,
    undefined,
    { params: { isVisited } }
  );
};
